<script setup lang="ts">
const pageSeoMeta = usePageSeoMeta()
pageSeoMeta.setLocalizedHeadAttributes()
pageSeoMeta.setLocalizedSeoMeta()

const { injectSkinClasses } = useThemeConfig()

onMounted(() => {
  // onMounted or onUpdated will NOT be called during SSR and will only be executed on the client

  // ℹ️ This will inject classes in body tag for accurate styling
  injectSkinClasses()
})
</script>

<template>
  <NuxtPage />
</template>
